import React, { useContext } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import commonStyles from 'utils/common-styles';
import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';
import Tags from 'common/Tags';
import { pageSize } from 'utils/devices';

import CompanyAndRoleModal from './CompanyAndRoleModal';
import TalentExperienceContext from './TalentExperienceContext';
import IndustryIcon from 'common/IndustryIcon';

const format = date => moment(date).format('MMM YYYY');

const StyledContainer = styled.div`
  position: relative;
`;

const Title = styled.div`
  ${commonStyles.heading_2}
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div.industry-icon {
    display: flex;
    align-items: center;
  }
`;
const Text = styled.div`
  ${commonStyles.caption}
  margin-bottom: 28px;
  text-transform: uppercase;
  opacity: 0.5;
  letter-spacing: 0.04em;
`;
const StyledRoleName = styled.span`
  @media ${pageSize.XS} {
    width: calc(100% - 70px);
  }
`;

const CompanyAndRole = ({ error, experienceId, isEditMode }) => {
  const {
    values: { companyAndRole, tags }
  } = useContext(TalentExperienceContext);
  const roleName = companyAndRole.role?.name;

  if (companyAndRole.company) {
    return (
      <StyledContainer>
        <Title>
          <div className="industry-icon">
            <IndustryIcon
              marginRight={'12px'}
              name={
                companyAndRole?.industryType?.name
                  ? companyAndRole?.industryType?.name
                  : companyAndRole?.industryType?.label
              }
            />
            <StyledRoleName>{roleName || companyAndRole.role}</StyledRoleName>
          </div>
          {isEditMode && (
            <ContainedButton
              type="button"
              opens="company-and-role-modal"
              data={{ experienceId }}
              backgroundColor="rgba(204, 213, 255, 0.11)"
              icon="edit"
            />
          )}
        </Title>
        <Text>
          {companyAndRole.company.name}, {format(companyAndRole.startDate)} -{' '}
          {companyAndRole.endDate ? format(companyAndRole.endDate) : 'now'}
        </Text>
        <Tags tags={tags} />
        <CompanyAndRoleModal />
      </StyledContainer>
    );
  }

  return (
    <>
      <Frame
        error={error}
        required
        text="Company and role"
        noDataText="Describe the basics of your work engagement"
        editMode="add"
        canEdit
        opens="company-and-role-modal"
        id="companyAndRole"
        className="companyAndRole-required"
      />
      <CompanyAndRoleModal />
    </>
  );
};

export default CompanyAndRole;
