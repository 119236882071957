const menuItems = [
  {
    label: 'Dashboard',
    url: '/backoffice/dashboard',
    icon: 'portfolio-grid',
    subMenuItems: []
  },
  {
    label: 'Operations',
    icon: 'operations',
    subMenuItems: [
      {
        label: 'Users',
        url: '/backoffice/operations/users'
      },
      {
        label: 'Soloist accounts',
        url: '/backoffice/operations/soloists'
      },
      {
        label: 'Company accounts',
        url: '/backoffice/operations/company-accounts'
      },
      {
        label: 'Vetting',
        url: '/backoffice/operations/vetting'
      },
      {
        label: 'Candidates',
        url: '/backoffice/operations/candidates'
      },
      {
        label: 'Unlock requests',
        url: '/backoffice/operations/profile-unlock'
      }
    ]
  },
  {
    label: 'Knowledge Base',
    icon: 'knowledge-base',
    subMenuItems: [
      {
        label: 'Disciplines and roles',
        style: { marginTop: 5 },
        url: '/backoffice/knowledge-base/disciplines-and-roles'
      },
      {
        label: 'Platforms',
        url: '/backoffice/knowledge-base/platforms'
      },
      {
        label: 'Tools',
        url: '/backoffice/knowledge-base/tools'
      },
      {
        label: 'Skills',
        url: '/backoffice/knowledge-base/skills'
      },
      {
        label: 'Genres',
        url: '/backoffice/knowledge-base/genres'
      },
      {
        label: 'Tags',
        url: '/backoffice/knowledge-base/tags'
      },
      {
        label: 'Companies',
        url: '/backoffice/knowledge-base/companies'
      },
      {
        label: 'Missing Items',
        url: '/backoffice/knowledge-base/missing-items'
      },
      {
        label: 'Missing Items (Services)',
        style: { marginTop: 3, marginBottom: 14 },
        url: `${process.env.REACT_APP_NEXT_BASE_URL}/backoffice/missing-items`
      }
    ]
  },
  {
    label: 'Admin Panel',
    icon: 'admin-panel',
    subMenuItems: [
      {
        label: 'Back office members',
        url: '/backoffice/admin-panel/members'
      }
    ]
  }
];

export default menuItems;
