import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import useViewport from 'hooks/useViewport';
import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';
import { pageSize } from 'utils/devices';
import useSearchService from 'hooks/api/useSearchService';
import { useSelector } from 'react-redux';
import { selectMyProfileId } from 'store/profile/profileSelectors';

const InputWrapper = styled.div`
  ${({ pt }) => pt && `padding-top: ${pt}px;`}
  ${({ pb }) => pb && `padding-bottom: ${pb}px;`}
	${({ mb }) => mb && `margin-bottom: ${mb}px;`}
	display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;
const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  top: 22px;
  left: 0px;
  transform: translateY(-50%);

  @media ${pageSize.S}, ${pageSize.XS} {
    &:hover {
      cursor: pointer;
      background-color: #bfcaff1a;
    }
  }

  ${props => props.iconTop && `top: ${props.iconTop}px`};
  ${props => props.iconBottom && `bottom: ${props.iconBottom}px`};
  ${props => props.iconRight && `right: ${props.iconRight}px`};
`;
const StyledInput = styled.input`
  ${commonStyles.ui_text_medium};
  height: 40px;
  padding-left: 48px;
  padding-right: 16px;
  font-size: 15px;
  outline: none;
  background: transparent;
  border: none;
  border-radius: 8px;
  border: 1px solid transparent;
  ${props => (props.error ? 'border: 2px solid #FF5151;' : '')}
  width: -webkit-fill-available;
  ${props => (props.disabled ? 'border: 2px solid rgba(230, 234, 255, 0.35);' : '')}
  ${props => (props.disabled ? 'color: rgba(230, 234, 255, 0.35);' : '')}
  ${({ isS }) => isS && 'background: #1A1B1E;'}
  &:focus {
    border: 1px solid #8a80ff;
    background: #1a1b1e;
  }
  @media ${pageSize.M} {
  }
  @media ${pageSize.S}, ${pageSize.XS} {
    ${({ showSearch }) => !showSearch && 'display: none;'}
  }
`;
const StyledInputContainer = styled.div`
  position: relative;
  ${props => props.fitContent && 'width: fit-content;'}
`;
const StyledInputWrapper = styled.div`
  display: flex;
  position: relative;
  max-width: 220px;
`;
const ClearSearchWrapper = styled.div`
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
`;
const ClearSearch = styled.div`
  padding: 6px 10px;
  border-radius: 4px;
  background: rgba(204, 213, 255, 0.11);
  cursor: pointer;
`;
const SearchBarDropDown = styled.div`
  position: absolute;
  bottom: -4px;
  transform: translateY(100%);
  width: 100%;
  background: #1a1b1e;
  border: 2px solid rgba(204, 213, 255, 0.11);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 4px 0;
  z-index: 100;
`;
const SearchBarDropDownItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: rgba(204, 213, 255, 0.05);
  }

  .search-dropdown-icon path {
    fill: rgba(229, 234, 255, 0.6);
  }
  .search-dropdown-icon path:hover {
    fill: rgba(229, 234, 255, 0.6);
  }
`;
const SearchBarDropDownItemDisabled = styled(SearchBarDropDownItem)`
  opacity: 0.4;
  cursor: auto;
`;

const SearchTextWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const SearchText = styled.span`
  ${commonStyles.ui_text_medium}
  text-transform: initial;
  color: ${({ color }) => color || 'rgba(229, 234, 255, 0.6)'};
  overflow: hidden;
`;
const SearchInText = styled.span`
  ${commonStyles.ui_text_tiny}
  text-transform: initial;
  color: rgba(229, 234, 255, 0.6);
`;

const FormSearch = (
  {
    error,
    fitContent,
    icon,
    iconBottom,
    iconRight,
    iconTop,
    maxLength,
    mb,
    onIconClick,
    pb,
    pt,
    searchIconColor,
    setShowSearch,
    showSearch
  },
  ref
) => {
  const inputRef = useRef();
  const suggestionDropdownRef = useRef();
  const searchInDropDownRef = useRef();
  const clearSearchRef = useRef();
  const profileId = useSelector(selectMyProfileId);
  const { isS } = useViewport();

  const [search, setSearch] = useState(new URL(window.location.href).searchParams.get('search') ?? '');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showSearchIn, setShowSearchIn] = useState(false);

  const { handleClear, handleKeyDown, handleSearch, handleSearchInJobs, handleSearchInPeople, handleSearchInService } =
    useSearchService();

  useEffect(() => {
    if (error) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
      inputRef.current.focus();
    }
  }, [error]);

  useEffect(() => {
    window.location.search.length === 0 && setSearch('');
    setShowSearchIn(false);
  }, [window.location.search]);

  const outsideClick = useCallback(
    e => {
      if (showSuggestions && !suggestionDropdownRef?.current?.contains(e.target)) {
        setShowSuggestions(!showSuggestions);
      }
      if (showSearchIn && !searchInDropDownRef?.current?.contains(e.target)) {
        setShowSearchIn(!showSearchIn);
      }
      if (
        showSearch &&
        !inputRef?.current?.contains(e.target) &&
        !clearSearchRef?.current?.contains(e.target) &&
        !suggestionDropdownRef?.current?.contains(e.target) &&
        !searchInDropDownRef?.current?.contains(e.target)
      ) {
        setShowSearch(!showSearch);
      }
    },
    [showSuggestions, suggestionDropdownRef, showSearchIn, showSearch, searchInDropDownRef]
  );

  useEffect(() => {
    if (showSuggestions || showSearchIn || showSearch) {
      document.body.addEventListener('mousedown', outsideClick, false);
    }
    return () => {
      document.body.removeEventListener('mousedown', outsideClick, false);
    };
  }, [showSuggestions, showSearchIn, outsideClick]);

  return (
    <InputWrapper pt={pt} mb={mb} pb={pb} ref={ref}>
      <StyledInputContainer fitContent={fitContent}>
        <StyledInputWrapper>
          <StyledInput
            value={search}
            onChange={e => handleSearch(e, setSearch, setShowSearchIn, setShowSuggestions, profileId)}
            onFocus={e => handleSearch(e, setSearch, setShowSearchIn, setShowSuggestions, profileId)}
            placeholder="Search"
            error={error}
            onKeyDown={e => handleKeyDown(e, search, setShowSearchIn)}
            ref={inputRef}
            maxLength={maxLength}
            showSearch={showSearch}
            isS={isS}
            autoComplete="off"
          />
          {search.length > 0 && (isS ? showSearch : true) && (
            <ClearSearchWrapper ref={clearSearchRef}>
              <ClearSearch onClick={() => handleClear(setSearch)}>
                <Icon size={8} icon="close" />
              </ClearSearch>
            </ClearSearchWrapper>
          )}
          {search.length > 0 && showSearchIn && (
            <SearchBarDropDown ref={searchInDropDownRef}>
              <SearchBarDropDownItem onClick={e => handleSearchInJobs(e, search, setShowSearchIn)}>
                <SearchTextWrapper>
                  <Icon icon="jobs" className="search-dropdown-icon" />
                  <SearchText>{search}</SearchText>
                </SearchTextWrapper>
                <SearchInText>in Jobs</SearchInText>
              </SearchBarDropDownItem>
              <SearchBarDropDownItem onClick={e => handleSearchInPeople(e, search, setShowSearchIn)}>
                <SearchTextWrapper>
                  <Icon icon="about" className="search-dropdown-icon" />
                  <SearchText>{search}</SearchText>
                </SearchTextWrapper>
                <SearchInText>in People</SearchInText>
              </SearchBarDropDownItem>
              <SearchBarDropDownItem onClick={e => handleSearchInService(e, search, setShowSearchIn)}>
                <SearchTextWrapper>
                  <Icon icon="services" className="search-dropdown-icon" />
                  <SearchText>{search}</SearchText>
                </SearchTextWrapper>
                <SearchInText>in Services</SearchInText>
              </SearchBarDropDownItem>
              <SearchBarDropDownItemDisabled>
                <SearchTextWrapper>
                  <Icon icon="company" className="search-dropdown-icon" />
                  <SearchText>{search}</SearchText>
                </SearchTextWrapper>
                <SearchInText>in Companies</SearchInText>
              </SearchBarDropDownItemDisabled>
            </SearchBarDropDown>
          )}
        </StyledInputWrapper>
        {icon && (
          <IconWrapper
            iconBottom={iconBottom}
            iconTop={iconTop}
            iconRight={iconRight}
            onClick={e => {
              onIconClick && onIconClick(e);
              setShowSearch && setShowSearch(true);
            }}
          >
            <Icon icon={icon} color={searchIconColor || 'rgba(209, 215, 255, 0.4)'} size={16} />
          </IconWrapper>
        )}
      </StyledInputContainer>
    </InputWrapper>
  );
};

export default React.forwardRef(FormSearch);
