import React from 'react';
import styled from '@emotion/styled';
import { Link, useLocation } from 'react-router-dom';

import commonStyles from 'utils/common-styles';
import Icon from 'common/Icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 240px;
  max-width: 240px;
  height: 38px;
  color: rgba(230, 234, 255, 0.35);
  ${props => props.isSelected && 'background: rgba(204, 213, 255, 0.11);'}

  &:hover {
    cursor: pointer;
    background: rgba(204, 213, 255, 0.04);
  }
`;

const StyledIcon = styled(Icon)`
  color: rgba(230, 234, 255, 0.35);
  margin: 16px;
  margin-right: 12px;
`;

const Text = styled.div`
  ${commonStyles.ui_text_medium}
  color: rgba(230, 234, 255, 0.35);
  ${props => props.isSelected && 'color: white'};
  padding-left: 44px;
  ${props => props.icon && 'padding-left: 0;'}
`;

const BackofficeSubMenuItem = ({ label, style = {}, icon, url }) => {
  const location = useLocation();
  const isSelected = location.pathname === url;

  if (url.startsWith('http')) {
    return (
      <a href={url} style={{ textDecoration: 'none', display: 'block', ...style }}>
        <Wrapper isSelected={isSelected}>
          {icon && <StyledIcon icon={icon} color={isSelected ? 'white' : 'rgba(230, 234, 255, 0.35)'} />}

          <Text isSelected={isSelected} icon={icon}>
            {label}
          </Text>
        </Wrapper>
      </a>
    );
  }

  return (
    <Link key={url} to={url} style={{ textDecoration: 'none', display: 'block', ...style }}>
      <Wrapper isSelected={isSelected}>
        {icon && <StyledIcon icon={icon} color={isSelected ? 'white' : 'rgba(230, 234, 255, 0.35)'} />}

        <Text isSelected={isSelected} icon={icon}>
          {label}
        </Text>
      </Wrapper>
    </Link>
  );
};

export default BackofficeSubMenuItem;
