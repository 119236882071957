import { useHistory, useLocation } from 'react-router-dom';

function useSearchService() {
  const location = useLocation();
  const history = useHistory();

  const handleSearch = (e, setSearch, setShowSearchIn, setShowSuggestions) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length > 0) {
      setShowSearchIn(true);
      setShowSuggestions(false);
    } else {
      setShowSearchIn(false);
      setShowSuggestions(true);
    }
  };

  const handleClear = setSearch => {
    setSearch('');
    history.push({
      pathname: location.pathname,
      search: ''
    });
  };

  const handleSearchInJobs = (e, search) => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/search/jobs?q=${search}`);
  };

  const handleSearchInPeople = (e, search) => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/search/talents?q=${search}`);
  };

  const handleSearchInService = (e, search) => {
    window.location.assign(`${process.env.REACT_APP_NEXT_BASE_URL}/search/services?q=${search}`);
  };

  const handleKeyDown = (e, search, setShowSearchIn) => {
    if (e.keyCode === 13) {
      if (location.pathname.includes('peoples')) {
        handleSearchInPeople(e, search, setShowSearchIn);
      } else {
        handleSearchInJobs(e, search, setShowSearchIn);
      }
    }
  };

  return {
    handleSearch,
    handleKeyDown,
    handleClear,
    handleSearchInJobs,
    handleSearchInPeople,
    handleSearchInService
  };
}

export default useSearchService;
