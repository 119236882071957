import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import { tagSelectors } from 'store/tags';
import { pageSize } from 'utils/devices';
import commonStyles from 'utils/common-styles';
import Tags from 'common/Tags';
import ContainedButton from 'common/ContainedButton';
import Frame from 'common/Frame';
import TalentTestimonial from 'pages/talent/TalentTestimonial';
import Credits from 'pages/talent/Credits';
import AllAdditionalTags from 'pages/talent/AllAdditionalTags';
import { profileSelectors } from 'store/profile';
import Accomplishments from './Accomplishments';
import MediaCoverageFrame from '../MediaCoverage/MediaCoverageFrame';
import CollaboratorsFrame from '../Collaborators/CollaboratorsFrame/CollaboratorsFrame';
import TalentDropdown from './TalentExperienceDropdown';
import useOutsideClick from 'hooks/useOutsideClick';
import DeleteExperienceModal from './DeleteExperienceModal';
import { portalActions } from 'store/portals';
import IndustryIcons from 'common/IndustryIcon';
import ViaTheXPlace from '../../../images/via-thexplace-logo.svg';
import { useMemo } from 'react';

function formatUrl(url, defaultProtocol = 'https:') {
  if (!url) {
    return '';
  }
  const trimmedUrl = url.trim();

  if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
    return trimmedUrl;
  }

  return `${defaultProtocol}//${trimmedUrl.replace(/^\/+/, '')}`;
}

const Spacer = styled.div`
  height: 0;
  border: 1px solid rgba(204, 213, 255, 0.11);
  margin-bottom: 24px;
`;
const FirstSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media ${pageSize.XS} {
    flex-direction: column-reverse;
    margin-top: -29px;
  }
`;
const StyledCollaboratorsFrame = styled(CollaboratorsFrame)`
  margin-bottom: 20px;
`;
const TextAndTagsWrapper = styled.div`
  flex: 1;
  @media ${pageSize.XS} {
    margin-top: 24px;
  }
`;
const Title = styled.h2`
  ${commonStyles.heading_2}
  margin-bottom: 8px;
  margin-top: 0px;
  display: flex;
`;
const Text = styled.div`
  ${commonStyles.caption}
  margin-bottom: 24px;
  opacity: 0.5;
`;
const TagsContainer = styled.div`
  margin-bottom: 26px;
`;
const ContentWrapper = styled.div`
  margin-bottom: 24px;
`;
const ReadMore = styled.div`
  margin-bottom: 64px;
`;
const ReadMoreButton = styled(ContainedButton)`
  background: rgba(221, 226, 255, 0.2);
  &:hover {
    box-shadow: 0px 2px 16px rgba(204, 213, 255, 0.11);
  }
`;
const StyledAllAdditionalTags = styled(AllAdditionalTags)`
  margin-top: 16px !important;
`;
const StyledViewExperienceFooterContainer = styled.div`
  background: rgba(204, 213, 255, 0.05);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const StyledCompanyLogoContainer = styled.div`
  width: 126px;
  height: 56px;
  background: rgba(204, 213, 255, 0.11);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
`;
const StyledDropdown = styled.div`
  position: absolute;
  top: 30px;
  right: -10px;
  box-sizing: border-box;
  background-color: #1a1b1e;
  border: 2px solid rgba(204, 213, 255, 0.11);
  border-radius: 16px;
  overflow: hidden;
  margin: 8px;
  z-index: 100;
  ${props => (props.isOpen ? '' : 'display: none;')}
`;
const CompanyLogo = styled.img`
  max-width: 80%;
  max-height: 80%;
`;
const StyledContainedButton = styled(ContainedButton)`
  height: 33px;
  @media ${pageSize.XS} {
    margin-left: auto;
    top: 29px;
  }
`;

const StyledImage = styled.img`
  display: inline-flex;
  height: 36px;
`;

const TalentExperienceItem = ({ job, toggleAddMode, updateEditedExperienceId }) => {
  const hasEditPermission = useSelector(profileSelectors.hasProfileEditPermission);
  const { companyAndRole, tags, testimonial } = job || {};
  const roleName = useSelector(tagSelectors.selectTagName(companyAndRole.role));
  const [creditsList, setCreditsList] = useState(job.credits);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [experienceEditMode, setExperienceEditMode] = useState(false);
  const toggleReadMore = () => setIsOpen(!isOpen);
  const dropdown = useRef();
  const dispatch = useDispatch();
  useOutsideClick(dropdown, () => {
    if (dropdownVisible) {
      setDropdownVisible(false);
    }
  });
  const viewExperience = () => {
    setIsOpen(true);
  };

  const editExperience = () => {
    toggleAddMode();
    updateEditedExperienceId(job._id);
    setExperienceEditMode(true);
  };

  const onChoose = newCreditId => setCreditsList([...creditsList, newCreditId]);

  // TODO: move this logic to backend
  const verifiedCollaborators = React.useMemo(
    () => job.collaborators?.filter(collaborator => collaborator.status === 'vetted' || hasEditPermission),
    [job.collaborators]
  );

  const showDropdown = e => {
    e.stopPropagation();
    setDropdownVisible(!dropdownVisible);
  };

  const handleDelete = () => {
    dispatch(portalActions.openPortal({ name: 'delete-experience-modal', data: { experienceId: job._id } }));
  };

  const companyLogoUrl = useMemo(() => {
    if (companyAndRole?.company?.image?.url) {
      return companyAndRole?.company?.image?.url;
    }
    if (companyAndRole?.company?.logo?.url) {
      return formatUrl(companyAndRole?.company?.logo?.url);
    }
    return null;
  }, [companyAndRole.company]);

  return (
    <>
      <FirstSectionWrapper>
        <TextAndTagsWrapper>
          <Title>
            {companyAndRole?.industryType?.name && (
              <IndustryIcons marginRight={'12px'} name={companyAndRole?.industryType?.name} />
            )}
            <span>{roleName || companyAndRole?.role}</span>{' '}
            {companyAndRole?.viaThexplace && <StyledImage src={ViaTheXPlace} />}
          </Title>
          <Text>
            {companyAndRole?.company?.name} ,&nbsp;{moment(companyAndRole.startDate).format('MMMM YYYY')} -&nbsp;
            {companyAndRole.endDate ? moment(companyAndRole.endDate).format('MMMM YYYY') : 'NOW'}
          </Text>
          <TagsContainer>
            <Tags tags={tags} useLimit={false} />
          </TagsContainer>
        </TextAndTagsWrapper>

        {companyAndRole.company && companyLogoUrl && (
          <StyledCompanyLogoContainer>
            {companyLogoUrl && <CompanyLogo src={companyLogoUrl} alt={companyAndRole.company?.logo?.name} />}
          </StyledCompanyLogoContainer>
        )}
        {hasEditPermission && (
          <>
            <StyledContainedButton onClick={showDropdown} backgroundColor="secondary">
              &#8226;&#8226;&#8226;
            </StyledContainedButton>
            <StyledDropdown ref={dropdown} isOpen={dropdownVisible}>
              <TalentDropdown handleDelete={handleDelete} editExperience={editExperience} />
            </StyledDropdown>
          </>
        )}
      </FirstSectionWrapper>

      {!isOpen && (
        <ReadMoreButton onClick={viewExperience} forceDisplayText>
          Read more
        </ReadMoreButton>
      )}

      {isOpen && (
        <ContentWrapper>
          <Accomplishments isEditMode={false} experienceId={job._id} mb="16" />
          {testimonial && (
            <Frame color="#B1FF33" text="Testimonial" withMarginBottom>
              <TalentTestimonial testimonial={testimonial} />
            </Frame>
          )}
          <Credits hideDropDown items={creditsList} fromEdit={true} noAdd onChoose={onChoose} formExperience />
          {verifiedCollaborators?.length ? (
            <StyledCollaboratorsFrame isEditMode={false} collaborators={verifiedCollaborators} />
          ) : (
            <></>
          )}
          {(experienceEditMode || job.mediaCoverage?.length > 0) && (
            <MediaCoverageFrame items={job.mediaCoverage} isEditMode={false} hasEditPermission={hasEditPermission} />
          )}
          <StyledAllAdditionalTags
            order={['platforms', 'genres', 'tools', 'skills', 'tags']}
            isEditMode={false}
            data={job}
          />
        </ContentWrapper>
      )}

      <ReadMore>
        {isOpen && (
          <StyledViewExperienceFooterContainer>
            <ContainedButton backgroundColor="secondary" onClick={toggleReadMore}>
              Show Less
            </ContainedButton>
            {hasEditPermission && (
              <ContainedButton icon="edit" backgroundColor="secondary" onClick={editExperience}>
                Edit experience
              </ContainedButton>
            )}
          </StyledViewExperienceFooterContainer>
        )}
      </ReadMore>

      <Spacer />
      <DeleteExperienceModal />
    </>
  );
};

export default TalentExperienceItem;
